<div class="row" *ngIf="user">
    <div class="col">
        <div class="form-group form-inline">
            <input class="form-control" type="text" [formControl]="searchTermControl" placeholder="Rechercher" />
            <select class="form-control ml-2" [formControl]="sessionFilterControl">
                <option value="all" default>Tous</option>
                <option *ngFor="let session of sessions" [value]="session.id">
                    {{session.code}}
                </option>
            </select>
            <small class="ml-2">
                Total: {{itemsCount}}
            </small>
        </div>
    </div>
    <div class="col">
        <ngb-pagination (pageChange)="onPageChange($event)" [page]="page" [pageSize]="pageSize"
            [collectionSize]="itemsCount"></ngb-pagination>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()" *ngIf="user.isStaff || user.isExamCenterBoss">
            <i class="fas fa-plus"></i>
            Ajouter une nouvelle pré-inscription
        </button>
    </div>
</div>
<app-loading-overlay [active]="loading">
    <table class="table table-striped" *ngIf="user">
        <thead>
            <tr>
                <ng-container>
                    <th scope="col">
                        Candidat
                    </th>

                    <th scope="col">
                        Session
                    </th>

                    <th scope="col">
                        Ticket
                    </th>

                    <th scope="col">
                        Numéro de dossier CPF
                    </th>

                    <th *ngIf="user.isStaff" scope="col">
                        Création
                    </th>

                    <th scope="col">
                        Convocations
                    </th>

                    <th scope="col">Actions</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let preregistration of preregistrations; index as i">
                <td>
                    <ngb-highlight [result]="preregistration.firstname" [term]="searchTermControl.value"
                        style="margin-right: 5px;">
                    </ngb-highlight>

                    <ngb-highlight [result]="preregistration.lastname" [term]="searchTermControl.value">
                    </ngb-highlight>

                    <br>

                    <ngb-highlight [result]="preregistration.email" [term]="searchTermControl.value">
                    </ngb-highlight>

                    <br>

                    <ngb-highlight [result]="preregistration.phoneNumber" [term]="searchTermControl.value">
                    </ngb-highlight>
                </td>

                <td>
                    <ngb-highlight [result]="preregistration.session.code" [term]="searchTermControl.value">
                    </ngb-highlight>
                </td>

                <td>
                    <ngb-highlight *ngIf="preregistration.exam" [result]="preregistration.exam.voucher_code"
                        [term]="searchTermControl.value">
                    </ngb-highlight>
                    <ng-container *ngIf="!preregistration.exam">
                        <span class="btn-assign text-secondary " (click)="openSelectExamModal(preregistration)">
                            En attente d'attribution
                        </span>
                    </ng-container>
                </td>

                <td>
                    <ngb-highlight [result]="preregistration.fileNumber" [term]="searchTermControl.value">
                    </ngb-highlight>
                </td>

                <td *ngIf="user.isStaff">
                    Le {{ preregistration.createdDate | date: 'dd/MM/yyyy' }}<br>
                    Par {{ preregistration.author.fullName }}
                </td>

                <td>
                    <ul>
                        <li *ngFor="let convocation of preregistration.convocations">
                            <span class="text-success">
                                Envoyé le {{ convocation.createdDate | date: 'dd/MM/yyyy' }} à {{ convocation.firstname
                                }}
                                {{ convocation.lastname }} pour la session {{ convocation.sessionCode }} et le voucher
                                {{
                                convocation.voucherCode }}
                            </span>
                        </li>
                    </ul>

                    <button class="btn btn-link" (click)="sendConvocation(preregistration)"
                        *ngIf="preregistration.exam != null && preregistration.exam.certification && !preregistration.session.isFinished && preregistration.studentConfirmedAt() == null && preregistration.ticketUsedAt() == null"
                        [disabled]="preregistration.convocationIsSending">
                        <span *ngIf="preregistration.convocationIsSending" class="button-loader"></span>
                        <span *ngIf="!preregistration.convocationIsSending">
                            <span *ngIf="preregistration.convocations.length > 0">Renvoyer la convocation</span>
                            <span *ngIf="preregistration.convocations.length == 0">Envoyer la convocation</span>
                        </span>
                    </button>

                    <p *ngIf="preregistration.exam == null" class="text-muted" style="margin-bottom: 0;">
                        Attribuez d'abord un ticket
                    </p>

                    <p *ngIf="preregistration.exam != null && preregistration.exam.certification == null"
                        class="text-muted" style="margin-bottom: 0;">
                        Attribuez d'abord une certification au ticket
                    </p>

                    <p *ngIf="preregistration.studentConfirmedAt() != null" class="text-muted"
                        style="margin-bottom: 0;">
                        Le candidat a confirmé sa participation le {{ preregistration.studentConfirmedAt() | date:
                        'dd/MM/yyyy'
                        }}
                    </p>

                    <p *ngIf="preregistration.session.isFinished" class="text-muted" style="margin-bottom: 0;">
                        La session est terminée
                    </p>

                    <p *ngIf="preregistration.ticketUsedAt() != null" class="text-muted" style="margin-bottom: 0;">
                        Le ticket a déjà été consommé le {{ preregistration.ticketUsedAt() | date: 'dd/MM/yyyy' }}
                    </p>
                </td>

                <td>
                    <ng-container
                        *ngIf="preregistration.ticketUsedAt() == null && preregistration.studentConfirmedAt() == null">
                        <button *ngIf="user.isStaff || user.isExamCenterBoss" class="btn btn-link"
                            (click)="edit(preregistration)">
                            <i class="fas fa-edit"></i>
                            Modifier
                        </button>
                        <br>
                        <button class="btn btn-link" style="color: red;" (click)="remove(preregistration)">
                            <i class="fas fa-trash"></i>
                            Annuler
                        </button>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>