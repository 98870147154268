import {ExamSession} from "./examSession";
import { Exam } from "./exam";
import {User} from "./user";


class Convocation {
    firstname: string;
    lastname: string;
    email: string;
    phoneNumber: string | null;
    sessionCode: string;
    voucherCode: string;
    createdDate: Date;

    constructor(data: any) {
        this.firstname = data.first_name;
        this.lastname = data.last_name;
        this.email = data.email;
        this.phoneNumber = data.phone_number;
        this.sessionCode = data.session_code;
        this.voucherCode = data.voucher_code;
        this.createdDate = new Date(data.created_date);
    }
}

export class Preregistration {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phoneNumber: string | null;
    session: ExamSession;
    exam: Exam | null = null;
    fileNumber: string | null = null;
    convocationSentAt: Date | null = null;
    convocationIsSending: boolean = false;

    convocations: Convocation[] = [];

    createdDate: Date;
    author: User;

    constructor (data: any) {
        if (data) {
            this.id = data.id;
            this.firstname = data.first_name;
            this.lastname = data.last_name;
            this.email = data.email;
            this.session = new ExamSession(data.session);
            this.createdDate = new Date(data.created_date);
            this.author = new User(data.author);
            
            if (data.phone_number) {
                this.phoneNumber = data.phone_number;
            }

            if (data.file_number) {
                this.fileNumber = data.file_number;
            }

            if (data.exam) {
                this.exam = new Exam(data.exam);
            }         

            if (data.convocation_sent_at) {
                this.convocationSentAt = new Date(data.convocation_sent_at);
            }

            for (let convocation of data.convocations) {
                this.convocations.push(new Convocation(convocation));
            }

        }
    }

    studentConfirmedAt() {
        if (this.exam == null) {
            return null;
        }

        return this.exam.studentConfirmedAt;
    }

    ticketUsedAt() {
        if (this.exam == null) {
            return null;
        }

        return this.exam.usedAt;
    }

    canSendConvocation() {
        return this.exam != null && this.exam.certification != null && this.studentConfirmedAt() == null && this.ticketUsedAt() == null;
    }

}

