import {CertificationCategory} from "./certificationCategory";
import {CertificationTest} from "./certificationTest";
import {CertificationMockTest} from "./certificationMockTest";

export class Certification {
    id: number;
    title: string;
    description: string;
    category: CertificationCategory;
    nb_passed_exams: number;
    nb_suceeded_exams: number;
    success_rate: number;
    tests: Array<CertificationTest>;
    mock_tests: Array<CertificationMockTest>;
    code: string;
    archived: boolean;

    constructor (data: any) {
        if (data) {
            this.id = data.id;
            this.title = data.title;
            this.description = data.description;
            this.nb_passed_exams = data.nb_passed_exams;
            this.nb_suceeded_exams = data.nb_suceeded_exams;
            this.success_rate = data.success_rate;
            if (data.category) {
                this.category = new CertificationCategory(data.category);
            }
            if (data.tests) {
                this.tests = data.tests.map((cnr: any) => new CertificationTest(cnr));
            }
            if (data.mock_tests) {
                this.mock_tests = data.mock_tests.map((cnr: any) => new CertificationMockTest(cnr));
            }
            this.code = data.code;
            this.archived = data.archived;
        }
    }

    toString (): string {
        return this.title;
    }
}
